




































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { DivisionModule, WorkOrderListModule } from '@/store/index';
import { FiltersModel } from './models/filters.model';
import ScheduleFilter from './filters/ScheduleFilter/ScheduleFilter.vue';
import PropertyFilter from './filters/PropertyFilter/PropertyFilter.vue';
import {
  FilterInput,
  FilterConditions,
  TransformFiltersToJson
} from '../../shared/services/filter/filter.service';
import { ScheduleType } from './filters/ScheduleFilter/schedule-filter.model';
import { PropertyType } from './filters/PropertyFilter/property-filter.model';
import moment from 'moment';
import {
  BS_DATEPICKER_FORMAT,
  convertDateArrayFormatFieldToAPIFormat,
  DATE_API_FORMAT
} from '@/utils/date.util';
import { createDivisionFilter } from '@/shared/services/division/division.helper';

@Component({
  components: { ScheduleFilter, PropertyFilter }
})
export default class MainFilters extends Vue {
  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.workOrderListModule.setWOType(this.$route.params.type);
    this.propertyFilter = new FilterInput({
      condition: FilterConditions.BeginsWith,
      search: null,
      field: PropertyType.ContainerNumber
    });

    this.search();
  }

  @Watch('division')
  onDivisionChange() {
    this.search();
  }

  workOrderListModule = WorkOrderListModule;
  divisionModule = DivisionModule;
  form: any;
  excelData: any;
  isValidDates = false;

  valueType = [
    { value: null, text: 'All' },
    { value: 'IN_TRANSIT', text: 'In transit' },
    { value: 'COMPLETED', text: 'Completed' }
  ];

  fromFilter = new FilterInput({
    condition: FilterConditions.GreaterOrEqual,
    search: moment()
      .subtract(6, 'days')
      .startOf('d')
      .format(DATE_API_FORMAT),
    field: ScheduleType.CreatedDate
  });

  toFilter = new FilterInput({
    condition: FilterConditions.LessThenOrEqual,
    search: moment()
      .endOf('d')
      .format(DATE_API_FORMAT),
    field: ScheduleType.CreatedDate
  });

  propertyFilter = new FilterInput({
    condition: FilterConditions.BeginsWith,
    search: null,
    field: PropertyType.ContainerNumber
  });

  statusFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: null,
    field: 'status'
  });

  constructor() {
    super();
    this.form = new FiltersModel();
  }

  get division() {
    return this.divisionModule.division;
  }

  search() {
    this.fromFilter.search = this.filterFrom(this.fromFilter.search);
    this.toFilter.search = this.filterTo(this.toFilter.search);
    this.workOrderListModule.setFilters(this.getFilters());
    this.workOrderListModule.search({
      type: this.$route.params.type,
    });
  }

  resetFilters() {
    this.statusFilter.search = null;
    (this.$refs as any).scheduleFilter.resetType();
    (this.$refs as any).propertyFilter.resetFilter();

    this.propertyFilter = new FilterInput({
      condition: FilterConditions.BeginsWith,
      search: null,
      field: PropertyType.OrderNumber
    });

    this.fromFilter.search = moment()
      .subtract(6, 'days')
      .format(DATE_API_FORMAT);
    this.toFilter.search = moment().format(DATE_API_FORMAT);

    this.workOrderListModule.setFilters(this.getFilters());
  }

  async excel() {
    this.fromFilter.search = this.filterFrom(this.fromFilter.search);
    this.toFilter.search = this.filterTo(this.toFilter.search);
    this.workOrderListModule.setFilters(this.getFilters());
    this.excelData = await this.workOrderListModule.excel(
      this.$route.params.type
    );

    if (this.excelData) {
      window.open(this.excelData.url);
    }
  }

  filterFrom(filter) {
    return convertDateArrayFormatFieldToAPIFormat(filter, [
      BS_DATEPICKER_FORMAT,
      DATE_API_FORMAT
    ]);
  }

  filterTo(filter) {
    return moment(filter, [BS_DATEPICKER_FORMAT, DATE_API_FORMAT])
      .endOf('day')
      .format(DATE_API_FORMAT);
  }

  private getFilters() {
    console.log(this.propertyFilter);
    this.propertyFilter.field === PropertyType.Driver
      ? (this.propertyFilter.condition = FilterConditions.Equal)
      : (this.propertyFilter.condition = FilterConditions.BeginsWith);

    return TransformFiltersToJson([
      this.toFilter,
      this.fromFilter,
      this.propertyFilter,
      this.statusFilter,
      createDivisionFilter()
    ]);
  }
}
