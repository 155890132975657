import { TableCategory, TableHeaders } from '@/shared/components/table/models';

export const Categories1TableHeaders: TableHeaders[] = [
  { key: 'number', label: 'NO', tdClass: 'min-w-50' },
  {
    key: 'date',
    label: 'Date'
  },
  {
    key: 'orderNumber',
    label: 'WO #'
  },
  {
    key: 'billTo',
    label: 'Bill To'
  }
];

export const EquipmentTableHeaders: TableHeaders[] = [
  {
    key: 'chassisNumber',
    label: 'Chassis #'
  },
  {
    key: 'chassisPool',
    rendererKey: 'equipment',
    label: 'POOL'
  }
];

export const InfoTableHeaders: TableHeaders[] = [
  {
    key: 'referenceNumber',
    label: 'REF'
  },
  {
    key: 'hot',
    rendererKey: 'info',
    label: 'HOT',
    formatter: v => (v ? 'Y' : 'N')
  }
];

export const POutTableHeaders: TableHeaders[] = [
  {
    key: 'pulloutLocation',
    label: 'P/OUT',
    tdClass: 'min-w-130'
  },
  {
    key: 'pulloutSchedule',
    rendererKey: 'pOut',
    label: 'S IN',
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'pulloutActualIn',
    rendererKey: 'pOut',
    label: 'A IN',
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'pulloutActualOut',
    rendererKey: 'pOut',
    label: 'A OUT',
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'pulloutDriver',
    rendererKey: 'pOut',
    label: 'DRV',
    collapsed: true,
    tdClass: 'min-w-130'
  }
];

export const DilvTableHeaders: TableHeaders[] = [
  {
    key: 'deliveryLocation',
    label: 'DILV',
    tdClass: 'min-w-130'
  },
  {
    key: 'deliverySchedule',
    rendererKey: 'dilv',
    label: 'S IN',
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'deliveryActualIn',
    rendererKey: 'dilv',
    label: 'A IN',
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'deliveryActualOut',
    rendererKey: 'dilv',
    label: 'A OUT',
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'deliveryDriver',
    rendererKey: 'dilv',
    label: 'DRV',
    collapsed: true,
    tdClass: 'min-w-130'
  }
];

export const PUTableHeaders: TableHeaders[] = [
  {
    key: 'pickupLocation',
    label: 'P/U',
    tdClass: 'min-w-130'
  },
  {
    key: 'loadConfirmation',
    rendererKey: 'pU',
    label: 'Load CFM',
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'pickupSchedule',
    rendererKey: 'pU',
    label: 'S IN',
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'pickupActualIn',
    rendererKey: 'pU',
    label: 'A IN',
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'pickupActualOut',
    rendererKey: 'pU',
    label: 'A OUT',
    collapsed: true,
    tdClass: 'min-w-130'
  },
  {
    key: 'pickupDriver',
    rendererKey: 'pU',
    label: 'DRV',
    collapsed: true,
    tdClass: 'min-w-130'
  }
];

export const RtnTableHeaders: TableHeaders[] = [
  {
    key: 'returnLocation',
    label: 'RTN',
    tdClass: 'min-w-130'
  },
  {
    key: 'returnSchedule',
    rendererKey: 'rtn',
    label: 'S IN',
    tdClass: 'min-w-130',
    collapsed: true
  },
  {
    key: 'returnActualIn',
    rendererKey: 'rtn',
    label: 'A IN',
    tdClass: 'min-w-130',
    collapsed: true
  },
  {
    key: 'returnActualOut',
    rendererKey: 'rtn',
    label: 'A OUT',
    tdClass: 'min-w-130',
    collapsed: true
  },
  {
    key: 'returnDriver',
    rendererKey: 'rtn',
    label: 'DRV',
    tdClass: 'min-w-130',
    collapsed: true
  }
];

export const ArTableHeaders: TableHeaders[] = [
  {
    key: 'ar',
    label: 'A/R'
  },
  {
    key: 'arTruckingCharges',
    rendererKey: 'ar',
    label: 'Trucking',
    collapsed: true
  },
  {
    key: 'arFuelSurCharges',
    rendererKey: 'ar',
    label: 'FSC/R',
    collapsed: true
  },
  {
    key: 'arChassis',
    rendererKey: 'ar',
    label: 'CH/R',
    collapsed: true
  },
  {
    key: 'arPerdiem',
    rendererKey: 'ar',
    label: 'PE/R',
    collapsed: true
  },
  {
    key: 'arDemurrage',
    rendererKey: 'ar',
    label: 'DE/R',
    collapsed: true
  },
  {
    key: 'arDryRun',
    rendererKey: 'ar',
    label: 'DR/R',
    collapsed: true
  }
];

export const ApTableHeaders: TableHeaders[] = [
  {
    key: 'apPerdiem',
    label: 'A/P'
  },
  {
    key: 'apDryRun',
    rendererKey: 'ap',
    label: 'DRV/P',
    collapsed: true
  },
  {
    key: 'apChassisFees',
    rendererKey: 'ap',
    label: 'CH/P',
    collapsed: true
  },
  {
    key: 'apPerdiem1',
    rendererKey: 'ap',
    label: 'PE/P',
    collapsed: true
  },
  {
    key: 'apDemurrage',
    rendererKey: 'ap',
    label: 'DE/P',
    collapsed: true
  },
  {
    key: 'apDriverPay',
    rendererKey: 'ap',
    label: 'DR/P',
    collapsed: true
  }
];

export const Categories3TableHeaders: TableHeaders[] = [
  {
    key: 'profit',
    label: 'Prft'
  },
  {
    key: 'inv',
    label: 'INV'
  }
];

export const DocumentTableHeaders: TableHeaders[] = [
  {
    key: 'filePod',
    label: 'POD',
    formatter: v => (v ? 'Y' : 'N')
  },
  {
    key: 'fileBl',
    rendererKey: 'document',
    label: 'B/L',
    collapsed: true,
    formatter: v => (v ? 'Y' : 'N')
  },
  {
    key: 'fileDo',
    rendererKey: 'document',
    label: 'DO',
    collapsed: true,
    formatter: v => (v ? 'Y' : 'N')
  },
  {
    key: 'fileIt',
    rendererKey: 'document',
    label: 'IT',
    collapsed: true,
    formatter: v => (v ? 'Y' : 'N')
  }
];

export const Categories4TableHeaders: TableHeaders[] = [
  {
    key: 'remarks',
    label: 'Remark'
  }
];

export const BareChassisCategoriesHeaders: TableCategory[] = [
  {
    key: 'category1',
    label: ' ',
    maxColspan: Categories1TableHeaders.length,
    minColspan: 1
  },
  {
    key: 'equipment',
    label: 'EQUIPMENT',
    maxColspan: EquipmentTableHeaders.length,
    minColspan: 1,
    class: 'equipment-color',
    collapsed: false,
    opportunityСhange: true
  },
  {
    key: 'info',
    label: 'INFO',
    maxColspan: InfoTableHeaders.length,
    minColspan: 1,
    class: 'info-color',
    collapsed: false,
    opportunityСhange: true
  },
  {
    key: 'pOut',
    label: 'P/OUT Dispatching',
    maxColspan: POutTableHeaders.length,
    minColspan: 1,
    class: 'p-out-color',
    collapsed: true,
    opportunityСhange: true
  },
  {
    key: 'dilv',
    label: 'DILV Dispatching',
    maxColspan: DilvTableHeaders.length,
    minColspan: 1,
    class: 'dilv-color',
    collapsed: true,
    opportunityСhange: true
  },
  {
    key: 'pU',
    label: 'P/U Dispatching',
    maxColspan: PUTableHeaders.length,
    minColspan: 1,
    class: 'p-u-color',
    collapsed: true,
    opportunityСhange: true
  },
  {
    key: 'rtn',
    label: 'RTN Dispatching',
    maxColspan: RtnTableHeaders.length,
    minColspan: 1,
    class: 'rtn-color',
    collapsed: true,
    opportunityСhange: true
  },
  {
    key: 'ar',
    label: 'AR',
    maxColspan: ArTableHeaders.length,
    minColspan: 1,
    class: 'ar-color',
    collapsed: true,
    opportunityСhange: true
  },
  {
    key: 'ap',
    label: 'AP',
    maxColspan: ApTableHeaders.length,
    minColspan: 1,
    class: 'ap-color',
    collapsed: true,
    opportunityСhange: true
  },
  {
    key: 'category3',
    label: '',
    maxColspan: Categories3TableHeaders.length,
    minColspan: 1
  },
  {
    key: 'document',
    label: 'Document',
    maxColspan: DocumentTableHeaders.length,
    minColspan: 1,
    class: 'document-color',
    collapsed: true,
    opportunityСhange: true
  },
  {
    key: 'category4',
    label: '',
    maxColspan: Categories4TableHeaders.length,
    minColspan: 1
  }
];

export const BareChassisTableHeaders: TableHeaders[] = [
  ...Categories1TableHeaders,
  ...EquipmentTableHeaders,
  ...InfoTableHeaders,
  ...POutTableHeaders,
  ...DilvTableHeaders,
  ...PUTableHeaders,
  ...RtnTableHeaders,
  ...ArTableHeaders,
  ...ApTableHeaders,
  ...Categories3TableHeaders,
  ...DocumentTableHeaders,
  ...Categories4TableHeaders
];
